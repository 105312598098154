import React, { useState } from 'react';
import { logout } from "redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { clearLocalData} from "redux/slices/dataSlice";

import { NavLink } from "react-router-dom";
import "./NavBar.sass";
import three_bar from "assets/three_bar.png"

export default function NavBar() {

  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logout());
    dispatch(clearLocalData());
  };

function openToggle() {
  document.getElementById("myDropdown").classList.toggle("show");
  console.log('called opentoggle')
}
window.onclick = function(e) {
  if (!e.target.matches(".dropbtn")) {
    var myDropdown = document.getElementById("myDropdown");
    if (myDropdown.classList.contains("show")) {
      myDropdown.classList.remove("show");
    }
  }
}
  return (
    <div>
      <nav className='navbar'>
        <a href="/" className='logo'>
          SmartProbe
        </a>

        <ul className='right'>
        <div className = "dropdown">
            <input 
              className = "dropbtn" 
              type = "image" 
              //src = {three_bar}
              alt = "Dropdown" 
              onClick = {() => openToggle()}>
            </input>
            <div className = "dropdown-content" id = "myDropdown">
              <li className = "dropdown-links">
              <NavLink to={{
                pathname:"/",
                state: {value: false }
                }}>
                Dashboard
              </NavLink>
              </li>
              <li className = "dropdown-links">
                <NavLink to="/session">Sessions</NavLink>
              </li>
              <li className = "dropdown-links">
                <NavLink to="/account">Account</NavLink>
              </li>
              <li className = "four-links">
              <NavLink to="/DataDownload">Data Download</NavLink>
              </li>
            </div>
          </div>
          <a className = "logout" onClick={() => onLogout()}>Logout</a>
        </ul>
      </nav>

      <nav className = 'navbar-overlap'>
        <ul className = "middle">
            <li className = "four-links">
              <NavLink to={{
                pathname:"/",
                state: {value: false }
              }}>
                Dashboard
              </NavLink>
            </li>
            <li className = "dropdown-links">
                <NavLink to="/session">Sessions</NavLink>
              </li>
            <li className = "four-links">
              <NavLink to="/account">Account</NavLink>
            </li>
            <li className = "four-links">
              <NavLink to="/DataDownload">Data Download</NavLink>
            </li>
          <div className = "dropdown">
            <input 
              className = "dropbtn" 
              type = "image" 
              src = {three_bar}
              alt = "Dropdown" 
              onClick = {() => openToggle()}>
            </input>
            <div className = "dropdown-content" id = "myDropdown">
              <li className = "dropdown-links">
              <NavLink to={{
                pathname:"/",
                state: {value: false }
                }}>
                Dashboard
              </NavLink>
              </li>
              <li className = "dropdown-links">
                <NavLink to="/session">Sessions</NavLink>
              </li>
              <li className = "dropdown-links">
                <NavLink to="/account">Account</NavLink>
              </li>
              <li className = "four-links">
              <NavLink to="/DataDownload">Data Download</NavLink>
              </li>
            </div>
          </div>
          
            
        </ul>
      </nav>

    </div>
  );
};
