import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./probeListSession.sass";
import {
  getInsectBySessionAndTrap,
} from "redux/slices/dataSlice";
export default function ProbeListSession({ resetSessionPageNum }) {
  const dispatch = useDispatch();
  const { probeDataList, sessionFlag } = useSelector((state) => state.data);
  const [selectedProbe, setSelectedProbe] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);

  useEffect(() => {
    resetSessionPageNum();
    setSelectedProbe(null);
    setSelectedSession(null);
  }, [sessionFlag]);

  const handleClick = (id) => {
    setSelectedProbe(probeDataList.probes[id]);
  };

  const handleSessionClick = (session) => {
    resetSessionPageNum();
    setSelectedSession(session);
    let sessionMsg = {
      sessionName: session,
      trapId: selectedProbe.trapId,
    }
    dispatch(getInsectBySessionAndTrap(sessionMsg));
    // Add any additional logic for when a session is clicked
  };

  const formatSessionName = (session) => {
    const parts = session.split("-");
    parts.pop(); // Remove the last part
    return parts.join("-");
  };

  const sortedProbes = probeDataList && probeDataList.probes
    ? Object.keys(probeDataList.probes).sort((a, b) => {
        const sessionsA = probeDataList.probes[a].activeSessions ? probeDataList.probes[a].activeSessions.length : 0;
        const sessionsB = probeDataList.probes[b].activeSessions ? probeDataList.probes[b].activeSessions.length : 0;
        return sessionsB - sessionsA;
      })
    : [];

  return (
    <div className="probeListSession-box">
      <div className="left-half">
        <div className="left-scrollList"> 
          <h3>SmartProbes:</h3>
          {sortedProbes.length > 0 ? sortedProbes.map((probe, index) => (
            <div
              key={index}
              className={`probe-item ${selectedProbe && selectedProbe.trapId === probe ? 'selected' : ''}`}
              onClick={() => handleClick(probe)}
            >
              <div>SmartProbe: {probeDataList.probes[probe].trapName ? probeDataList.probes[probe].trapName : probe}</div>
              <div># of Sessions: {probeDataList.probes[probe].activeSessions ? probeDataList.probes[probe].activeSessions.length : 0}</div>
              {/* Add more properties as needed */}
            </div>
          )) : <div>No SmartProbe</div>}
        </div>
      </div>
      <div className="right-half">
        {selectedProbe ? (
          <div className="probe-details">
            <h3>Saved Sessions for {selectedProbe.trapName || selectedProbe.trapId}</h3>
            {selectedProbe.activeSessions && selectedProbe.activeSessions.length > 0 ? (
              <div>
                {selectedProbe.activeSessions.map((session, index) => (
                  <div key={index} 
                  className={`session-item ${selectedSession && selectedSession === session ? 'selected' : ''}`}
                  onClick={() => handleSessionClick(session)}>
                    {formatSessionName(session)}
                  </div>
                ))}
              </div>
            ) : (
              <div>No saved sessions</div>
            )}
          </div>
        ) : (
          <div className="noDataTextContainer">
            <h3>Select a probe to see its saved sessions.</h3>
          </div>
        )}
      </div>
    </div>
  );
}
