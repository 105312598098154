import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import './Session.sass';
import {
    allProbeDataFromAccount,
  } from "redux/slices/dataSlice";
import ProbeListSession from 'components/box/probeListSession-box/probeListSession/probeListSession';
import ProbeDataSession from 'components/box/probeListSession-box/probeDataSession/probeDataSession';

export default function Session(){
    const dispatch = useDispatch();
    const [sessionPageNum, setSessionPageNum] = useState(1);

    const {currTeam } = useSelector(
    (state) => state.auth
    );

    const resetSessionPageNum = () => {
      setSessionPageNum(1);
    };

    useEffect(() => {
        let msg = {
          teamName: currTeam,
        };
        dispatch((allProbeDataFromAccount(msg)));
      }, []);

    const resetSessionPage = () => {
      let msg = {
        teamName: currTeam,
      };
      dispatch((allProbeDataFromAccount(msg)));
      setSessionPageNum(1);
    }

    return (
        <div className="session">
        <div className="session-body">
            <div className="session-left-block">          
              <ProbeListSession sessionPageNum={sessionPageNum} resetSessionPageNum={resetSessionPageNum} />  
            </div>
            <div className="session-right-block">
                <ProbeDataSession sessionPageNum={sessionPageNum} setSessionPageNum={setSessionPageNum} resetSessionPage={resetSessionPage}/>     
            </div>
        </div>
    </div>
    );
}

