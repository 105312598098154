import React, { useState } from "react";
import {useSelector, useDispatch } from "react-redux";
import {cleanTrap, fetchScrollInfoRefresh, fetchTrapInfo, fetchDataLst} from "redux/slices/dataSlice";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import { DialogContentText, DialogTitle } from "@mui/material";
import loadingIcon from 'assets/loading.gif';

import './trap-functions.sass';

export default function CleanProbe({setOpenFuncs, handleClosingFunction, pageNum}){
    const dispatch = useDispatch();
    const { trapId, dataLst } = useSelector((state) => state.data);
    const {filter, ascend, currTeam} = useSelector((state) => state.auth);
    const [newSessionName, setSessionName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sessionNameExist, setSessionNameExist] = useState(false);

    const handleSessionName = (event) => {
        setSessionName(event.target.value);
    }
    const submitClean = async () => {
        setLoading(true);
        let response = await dispatch(cleanTrap({trapId: trapId, sessionName: newSessionName, teamName: currTeam}));
        if (response.payload.message !== "Invalid Session Name") {
            setSessionNameExist(false);
            let payload = {
              msg: {
                filter: filter,
                ascend: ascend,
                teamName: currTeam,
              },
              num: pageNum,
            };
            await dispatch(fetchScrollInfoRefresh(payload));
            await dispatch(fetchTrapInfo(trapId));
            //Refreshes info in infobox
            dispatch(fetchDataLst(trapId));

        }
        
        setLoading(false);
        setOpenFuncs(false);
    }

    return(
        loading ? (
            <DialogContent >
                <img src={loadingIcon} alt="Loading..." className = "loading-icon"></img>
                <DialogContentText textAlign = "center"> <b>Data is being stored <br></br> 
                This may take a minute...</b></DialogContentText> 

            </DialogContent>
        ): ( 
            <div id = "cleanprobe">
            <DialogContent>
                <Button variant = "outlined" className = "custom-button" onClick = {() => handleClosingFunction() }>Back</Button>
                <DialogTitle textAlign = "center"> <b>Save Data into a Session</b> </DialogTitle>
                <DialogContentText> Enter a name for your session below. Data will be removed from active dashboard and saved into a Session. Sessions are able to be viewed seperately and will still be available for download. </DialogContentText>
                <TextField
                    fullWidth
                    margin="normal"
                    id="outlined-size-normal"
                    label="Session Name"
                    value={newSessionName}
                    onChange={handleSessionName}
                    placeholder="Add Session Name"
                />
                <Button variant = "outlined" className = "custom-button" onClick = {() => submitClean()}>Store into Session</Button>
            </DialogContent>
        </div>
        )
        
    );
};