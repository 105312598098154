import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./probeDataSession.sass";
import {
  moveInsectsToDeleted,
} from "redux/slices/dataSlice";
import InfoBar from "components/info-bar/info-bar-2/InfoBar2";
import ParticleCountInfoBar from "components/info-bar/info-bar-2/ParticleCountInfoBar";
import InsectCounterInfoBar from "components/info-bar/info-bar-2/InsectCounterInfoBar";
import PageBar from "components/info-bar/page-bar/PageBar";
import ConfirmationModal from "components/modal/ConfirmationModal/ConfirmationModal"; // Import the confirmation modal

export default function ProbeDataSession({ sessionPageNum, setSessionPageNum, resetSessionPage}) {
  const dispatch = useDispatch();
  const { sessionProbeData, sessionFlag } = useSelector((state) => state.data);
  const [page, setPage] = useState(1);
  const [btnDisable, setBtnDisable] = useState(false);
  const [navBtnDisable, setNavBtnDisable] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false); // State to control the confirmation modal

  useEffect(() => {
    if (sessionProbeData === null) {
      setNavBtnDisable(true);
    } else {
      setNavBtnDisable(false);
    }
  }, [sessionProbeData]);

  const handleDelete = () => {
    setShowConfirmation(true); // Show the confirmation modal
  };

  const confirmDelete = () => {
    // Add logic to handle deletion
    let msg = {
      "sessionName": sessionProbeData[sessionPageNum - 1]["session"],
      "trapId": sessionProbeData[sessionPageNum - 1]["trapId"],
    }
    dispatch(moveInsectsToDeleted(msg));
    setShowConfirmation(false); // Hide the confirmation modal
    resetSessionPage();
    alert("Please allow a few minutes for session to be deleted.");
  };

  const cancelDelete = () => {
    setShowConfirmation(false); // Hide the confirmation modal
  };
  let dateDI = "";
  if (sessionProbeData && sessionProbeData.length > 0 && sessionProbeData[page - 1].createdAt) {
    let createdAt = sessionProbeData[sessionPageNum - 1].createdAt;
    let utcdate = new Date(createdAt);
    dateDI = utcdate.toLocaleString();
  }
  return (
    <div className="probeDataSession-box">
      {sessionProbeData && sessionProbeData[sessionPageNum]?
        <div className ="probeDataSession-container">
          <div className ="session-top-half">
          <div className="session-pic-section">
        {sessionProbeData && sessionProbeData[sessionPageNum - 1] ?
          <input
            type="image"
            className="pic"
            src={sessionProbeData[sessionPageNum - 1]["originalFileUrl"]}
            alt="not available"
            //onClick={() => setPop(true)}
            //disabled={btnDisable}
          /> : <input
            type="image"
            className="pic-logo"
            src="https://particledetection-aivs-processed.s3.us-west-1.amazonaws.com/original/aivflogo2023.png"
            alt="not available"
            //onClick={() => setPop(true)}
            //disabled={btnDisable}
          /> 
        }
        </div>
        <div className="info-bar1-page-bar">
          <PageBar
            content={sessionProbeData ? sessionPageNum + "/" + sessionProbeData.length : "0/0"}
            totalPage={sessionProbeData ? sessionProbeData.length - 1 : 0}
            page={sessionPageNum}
            setPage={setSessionPageNum}
            handleDisable={navBtnDisable}
          />
            </div>
          </div>
          <div className ="session-bottom-half">
            {sessionProbeData && sessionProbeData.length > 0 ? (   
              <div className="session-bottom-container">
                <div className="session-bottom-left">       
                <InfoBar title={"trapId"} key={"trapId"} value={sessionProbeData[sessionPageNum - 1]["trapId"]} />
                <InfoBar title={"Building"} value={sessionProbeData[sessionPageNum - 1]["building"]}/>
                <InfoBar title={"Location"} value={sessionProbeData[sessionPageNum - 1]["location"]}/>
                <InfoBar title="Date" key={"createdAt"} value={dateDI} />
                <InfoBar title="Time" key={"createdAt"} value={dateDI} />
                </div>
                <div className="session-bottom-right">
                <ParticleCountInfoBar title = "Total Count" key={"particleCount"} value={sessionProbeData[sessionPageNum - 1]["particleCount"]} />
                <InsectCounterInfoBar title = "Moth Count" insect={"moth"} value={sessionProbeData[sessionPageNum - 1].insectCollection.moth} editFlag="false"/>
                <InsectCounterInfoBar title = "Beetle Count" insect={"beetle"} value={sessionProbeData[sessionPageNum - 1].insectCollection.beetle} editFlag="false"/>
                <InsectCounterInfoBar title = "Other Count" insect={"other"} value={sessionProbeData[sessionPageNum - 1].insectCollection.other} editFlag="false"/>
                <InfoBar title={"temperature"} key={"temperature"} value={sessionProbeData[sessionPageNum - 1]["temperature"]} />
                <InfoBar title={"humidity"} key={"humidity"} value={sessionProbeData[sessionPageNum - 1]["humidity"]} />
                </div>
              </div>
                ) : (
              <InfoBar title={"trapId"} key={"404"} value={sessionProbeData[sessionPageNum - 1]["trapId"]} />
            )}
            <div className="delete-button-container">
              <button className="delete-button" onClick={handleDelete}>Delete Session</button>
            </div>
          </div>
        </div>
      : <div className="noDataTextContainer">
          <h3>Select a saved session to view data. </h3>
        </div>}
        {showConfirmation && (
        <ConfirmationModal
          message="Are you sure you want to delete this session?"
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      )}
    </div>
  );
}